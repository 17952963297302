@import '@/styles/forms';
@import '@/styles/mixins';
.container {
    margin: 0 auto;
    @include screen('small-mobile') {
        width: 350px;
    }
    @include screen('xsmall-tablet') {
        width: 560px;
    }
}
.intro {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    gap: 12px;
}
.recoveryWrapper {
    margin-top: 2px;
    display: flex;
    justify-content: flex-end;

    .link {
        color: $button-dark;
    }
}

.row {
    padding-top: 40px;
    @include screen('desktop') {
        padding-top: 56px;
    }
}
